import { useMutation } from '@tanstack/react-query';

import { api } from '../../../api';

interface CreateCheckoutSessionResponse {
  sessionId: string;
}

interface CreateCheckoutSessionMutationPayload {
  priceIds: string[];
  promoCode?: string;
}

export default function useCreateCheckoutSession(currencyCode: string = 'gbp') {
  return useMutation({
    mutationFn: (payload: CreateCheckoutSessionMutationPayload) =>
      api
        .post<CreateCheckoutSessionResponse>(
          `/payments/create-checkout-session?currencyCode=${currencyCode}`,
          payload
        )
        .then((res) => res.data),
  });
}
