import { useContext, useEffect, useState } from 'react';

import { PromoCodeModal, SelectionButton } from './components';
import ProductDescriptionSheet from './components/productDescriptionSheet/ProductDescriptionSheet';

import { loadStripe } from '@stripe/stripe-js';
import { ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { LoadingSpinnerScreen, TabBar } from '../../components';
import Button from '../../components/button/Button';
import analytics from '../../config/segmentClient';
import { UserContext } from '../../contexts';
import {
  useCreateCheckoutSession,
  useGetUpcomingInvoice,
} from '../../hooks/mutations';
import { PlusCircleIcon } from '../../icons';
import { theme } from '../../styles/theme';
import { hexToRgbA } from '../../styles/utils';
import {
  METADATA_DNA_UPLOAD,
  METADATA_DNA_UPLOAD_FREE,
  METADATA_GENETIC_ANALYSIS_PACK,
  PaymentPlans,
  PromotionCode,
  SubscriptionPrice,
  SubscriptionPriceTimePeriod,
} from '../../types/payments';
import { formatCurrency } from '../../utils/formats';
import styles from './PaymentPlanSelectionScreen.styles.';

export type SelectedPaymentPlan = {
  subscriptionPrice?: SubscriptionPrice;
  geneticAnalysisPackPrice?: SubscriptionPrice;
  bundlePrice?: SubscriptionPrice;
};

type PaymentPlanSelectionScreenProps = {
  paymentPlansOptions?: PaymentPlans;
  selectedPaymentPlan: SelectedPaymentPlan;
  setSelectedPaymentPlan: (selectedPaymentPlan: SelectedPaymentPlan) => void;
};

type OpenedModal =
  | 'REVIVE_SUBSCRIPTION'
  | 'GENETIC_ANALYSIS_PACK'
  | 'PROMO_CODE'
  | null;

const sortedTimePeriods: SubscriptionPriceTimePeriod[] = [
  'monthly',
  '3 months',
  '6 months',
  '12 months',
];

const sortSubscriptionPrices = (
  priceA: SubscriptionPrice,
  priceB: SubscriptionPrice
) => {
  const timePeriodIndexA = sortedTimePeriods.findIndex(
    (sortedTimePeriod) => sortedTimePeriod === priceA.timePeriod
  );
  const timePeriodIndexB = sortedTimePeriods.findIndex(
    (sortedTimePeriod) => sortedTimePeriod === priceB.timePeriod
  );

  return timePeriodIndexA - timePeriodIndexB;
};

const stripePromise = loadStripe(
  process.env['REACT_APP_STRIPE_PUBLISHABLE_KEY'] || ''
);

export default function PaymentPlanSelectionScreen({
  paymentPlansOptions,
  selectedPaymentPlan,
  setSelectedPaymentPlan,
}: PaymentPlanSelectionScreenProps) {
  const [searchParams] = useSearchParams();
  const language = searchParams.get('language') || 'en-US';
  const currency = searchParams.get('currencyCode') || 'gbp';

  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const [openedModal, setOpenedModal] = useState<OpenedModal>(null);
  const [promotionCode, setPromotionCode] = useState<PromotionCode>();
  const [promoCodeError, setPromoCodeError] = useState('');
  const [activeTabIndex, setActiveTabIndex] = useState(1);

  const { mutateAsync: getUpcomingInvoice, data: upcomingInvoice } =
    useGetUpcomingInvoice(currency);

  const {
    mutateAsync: createCheckoutSession,
    data: session,
    isPending,
  } = useCreateCheckoutSession(currency);

  if (!paymentPlansOptions) {
    <LoadingSpinnerScreen />;
  }

  const subscriptionsPrices = paymentPlansOptions?.subscriptionsPrices
    .filter((plan) => plan.timePeriod !== 'monthly')
    .sort(sortSubscriptionPrices);

  const tabLabels =
    subscriptionsPrices?.map((price) => price?.timePeriod || '') || [];

  const subscriptionPrice = subscriptionsPrices?.[activeTabIndex];

  const freeDnaUploadPrice =
    paymentPlansOptions?.geneticAnalysisPackPrices.find(
      (price) => price.metadata?.['category'] === METADATA_DNA_UPLOAD_FREE
    );

  const dnaUploadPrice = paymentPlansOptions?.geneticAnalysisPackPrices.find(
    (price) => price.metadata?.['category'] === METADATA_DNA_UPLOAD
  );

  const geneticAnalysisPackPricesOptions = freeDnaUploadPrice
    ? paymentPlansOptions?.geneticAnalysisPackPrices?.filter(
        (price) => price.metadata?.['category'] !== METADATA_DNA_UPLOAD
      )
    : paymentPlansOptions?.geneticAnalysisPackPrices;

  const fullGeneticAnalysisPackPrice = geneticAnalysisPackPricesOptions?.find(
    (geneticAnalysisPackPrice) =>
      (geneticAnalysisPackPrice?.metadata?.category as string) ===
      METADATA_GENETIC_ANALYSIS_PACK
  );

  const bundlePrice = paymentPlansOptions?.bundlePrice;

  useEffect(() => {
    const priceIds = Object.values(selectedPaymentPlan).reduce((acc, curr) => {
      if (curr) {
        return [...acc, curr.id];
      }

      return acc;
    }, [] as string[]);

    if (priceIds.length > 0) {
      getUpcomingInvoice({
        priceIds,
        promoCode: promotionCode?.code || null,
      });
    }
  }, [selectedPaymentPlan, promotionCode]);

  // Pre-select the subscription option inside
  useEffect(() => {
    if (!selectedPaymentPlan?.bundlePrice) {
      setSelectedPaymentPlan({
        ...selectedPaymentPlan,
        subscriptionPrice,
        bundlePrice: undefined,
      });
    }
  }, [subscriptionPrice]);

  // Pre-select the genetic analysis option if any subscription is selected
  useEffect(() => {
    if (
      selectedPaymentPlan?.subscriptionPrice &&
      !selectedPaymentPlan.geneticAnalysisPackPrice
    ) {
      setSelectedPaymentPlan({
        ...selectedPaymentPlan,
        geneticAnalysisPackPrice: fullGeneticAnalysisPackPrice,
      });
    }
  }, [selectedPaymentPlan]);

  const handleSelectionPaymentPlan = (
    type: keyof PaymentPlans,
    price: SubscriptionPrice
  ) => {
    if (type === 'subscriptionsPrices') {
      return setSelectedPaymentPlan({
        ...selectedPaymentPlan,
        subscriptionPrice: price,
        bundlePrice: undefined,
      });
    }

    if (type === 'geneticAnalysisPackPrices') {
      return setSelectedPaymentPlan({
        ...selectedPaymentPlan,
        geneticAnalysisPackPrice: price,
        bundlePrice: undefined,
      });
    }

    if (type === 'bundlePrice') {
      return setSelectedPaymentPlan({
        bundlePrice: price,
      });
    }
  };

  const goToCheckout = async () => {
    if (!selectedPaymentPlan || !Object.values(selectedPaymentPlan)?.length) {
      return navigate('/');
    }

    await analytics.track({
      event: 'Checkout Session Started',
      type: 'track',
      userId: user?.id,
      properties: {
        userId: user?.id,
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
      },
    });

    createCheckoutSession({
      priceIds: Object.values(selectedPaymentPlan)
        .filter((paymentPlan) => paymentPlan)
        .map((price) => price.id),
      promoCode: promotionCode?.code,
    });
  };

  useEffect(() => {
    async function redirectToCheckout() {
      if (!session?.sessionId) {
        return;
      }

      const stripe = await stripePromise;

      await stripe?.redirectToCheckout({
        sessionId: session?.sessionId,
      });
    }

    redirectToCheckout();
  }, [session]);

  // either (bundle) is selected or (a pack in genetic Analysis + subscription) is selected
  const isPaymentSelectionComplete =
    selectedPaymentPlan.bundlePrice ||
    (selectedPaymentPlan.geneticAnalysisPackPrice &&
      selectedPaymentPlan.subscriptionPrice);

  const ongoingSubscription = selectedPaymentPlan.bundlePrice
    ? paymentPlansOptions?.subscriptionsPrices.find(
        (price) => price.timePeriod === '12 months'
      )
    : selectedPaymentPlan.subscriptionPrice;

  const twelveMonthsSubscriptionPrice =
    paymentPlansOptions?.subscriptionsPrices?.find(
      (price) => price.timePeriod === '12 months'
    )?.amount || 0;

  const geneticAnalysisPackPrice =
    paymentPlansOptions?.geneticAnalysisPackPrices?.find(
      (price) => price.metadata?.['category'] === METADATA_GENETIC_ANALYSIS_PACK
    )?.amount || 0;

  const savingsWithBundlePrice =
    twelveMonthsSubscriptionPrice +
    geneticAnalysisPackPrice -
    (paymentPlansOptions?.bundlePrice?.amount || 0);

  return (
    <View style={styles.container}>
      <div style={{ position: 'fixed', top: 15, right: 16, zIndex: 100 }}>
        <TouchableOpacity onPress={() => setOpenedModal('PROMO_CODE')}>
          <Text style={styles.underlinedText}>Have a promo code?</Text>
        </TouchableOpacity>
      </div>
      <ScrollView style={styles.scrollContainer}>
        <View style={styles.contentContainer}>
          {promotionCode && (
            <View style={styles.promoCodeInfoContainer}>
              <Text
                style={styles.description}
              >{`Your code '${promotionCode.code}' has been applied`}</Text>

              <TouchableOpacity
                onPress={() => {
                  setPromotionCode(undefined);
                }}
              >
                <Text style={styles.underlinedText}>Remove</Text>
              </TouchableOpacity>
            </View>
          )}
          <View style={styles.subscriptionContainer}>
            <Text style={styles.title}>Revive Subscription</Text>

            <View style={styles.tabBarContainer}>
              <TabBar
                activeTabIndex={activeTabIndex}
                setActiveTabIndex={setActiveTabIndex}
                tabLabels={tabLabels}
              />
            </View>

            <View style={styles.priceList}>
              {subscriptionPrice && (
                <SelectionButton
                  selected={
                    selectedPaymentPlan?.subscriptionPrice?.id ===
                    subscriptionPrice.id
                  }
                  onPress={() =>
                    handleSelectionPaymentPlan(
                      'subscriptionsPrices',
                      subscriptionPrice
                    )
                  }
                >
                  <View style={styles.labelContainerRow}>
                    <Text style={styles.priceBold}>
                      {formatCurrency({
                        amount: subscriptionPrice.amount,
                        languageTag: language,
                        currency: subscriptionPrice.currency,
                      })}
                    </Text>
                    <Text style={styles.description}>{` / ${
                      tabLabels[activeTabIndex]
                    } ${
                      subscriptionPrice?.timePeriod === 'monthly'
                        ? '(cancel anytime)'
                        : ''
                    } `}</Text>
                  </View>
                </SelectionButton>
              )}
            </View>

            <TouchableOpacity
              style={styles.whatsIncludedContainer}
              onPress={() => setOpenedModal('REVIVE_SUBSCRIPTION')}
            >
              <View style={styles.plusCircleIconContainer}>
                <PlusCircleIcon color={theme.palette.green[100]} />
              </View>
              <Text style={styles.description}>What does this include?</Text>
            </TouchableOpacity>

            <View style={styles.divider} />

            <View>
              <Text style={styles.title}>Genetic Analysis Pack</Text>
              <Text style={styles.description}>
                This will help us to optimise your health
              </Text>

              <View style={styles.priceList}>
                {geneticAnalysisPackPricesOptions?.map(
                  (geneticAnalysisPackPrice) => (
                    <SelectionButton
                      selected={
                        selectedPaymentPlan?.geneticAnalysisPackPrice?.id ===
                        geneticAnalysisPackPrice.id
                      }
                      onPress={() =>
                        handleSelectionPaymentPlan(
                          'geneticAnalysisPackPrices',
                          geneticAnalysisPackPrice
                        )
                      }
                    >
                      <View style={styles.labelContainer}>
                        {geneticAnalysisPackPrice?.metadata?.['category'] !==
                          METADATA_GENETIC_ANALYSIS_PACK && (
                          <View>
                            <Text style={{ textAlign: 'center' }}>
                              I have my DNA file
                            </Text>
                          </View>
                        )}

                        <View style={styles.freePriceContainer}>
                          <Text
                            style={
                              geneticAnalysisPackPrice?.metadata?.[
                                'category'
                              ] === METADATA_DNA_UPLOAD_FREE
                                ? [styles.priceBold, styles.strikeThrough]
                                : styles.priceBold
                            }
                          >
                            {formatCurrency({
                              amount:
                                geneticAnalysisPackPrice?.metadata?.[
                                  'category'
                                ] === METADATA_DNA_UPLOAD_FREE
                                  ? dnaUploadPrice?.amount || 0
                                  : geneticAnalysisPackPrice.amount,
                              languageTag: language,
                              currency:
                                dnaUploadPrice?.currency ||
                                geneticAnalysisPackPrice?.currency,
                            })}
                          </Text>
                          {geneticAnalysisPackPrice?.metadata?.['category'] ===
                            METADATA_DNA_UPLOAD_FREE && (
                            <Text style={[styles.freeText]}>Free</Text>
                          )}
                        </View>
                      </View>
                    </SelectionButton>
                  )
                )}
              </View>

              <TouchableOpacity
                style={styles.whatsIncludedContainer}
                onPress={() => setOpenedModal('GENETIC_ANALYSIS_PACK')}
              >
                <View style={styles.plusCircleIconContainer}>
                  <PlusCircleIcon color={theme.palette.green[100]} />
                </View>
                <Text style={styles.description}>What does this include?</Text>
              </TouchableOpacity>
            </View>
          </View>

          <View style={styles.orContainer}>
            <View style={styles.or}>
              <Text style={styles.description}>Or</Text>
            </View>
          </View>

          <View style={styles.subscriptionContainer}>
            <Text style={styles.title}>Bundle</Text>
            <Text style={styles.description}>
              Genetic Analysis Pack + 12 months subscription
            </Text>

            <View style={styles.priceList}>
              {bundlePrice && (
                <SelectionButton
                  selected={
                    selectedPaymentPlan?.bundlePrice?.id === bundlePrice.id
                  }
                  onPress={() =>
                    handleSelectionPaymentPlan('bundlePrice', bundlePrice)
                  }
                >
                  <View style={styles.labelContainerRow}>
                    <Text style={styles.priceBold}>
                      {formatCurrency({
                        amount: bundlePrice.amount,
                        languageTag: language,
                        currency: bundlePrice?.currency,
                      })}{' '}
                      {` (save ${formatCurrency({
                        amount: savingsWithBundlePrice,
                        languageTag: language,
                        currency: bundlePrice?.currency,
                      })})`}
                    </Text>
                  </View>
                </SelectionButton>
              )}
            </View>
          </View>
        </View>
      </ScrollView>

      <div
        style={{
          ...styles.footer,
          position: 'fixed',
          borderTop: `1px solid ${hexToRgbA('#1E3E30', 0.2)}`,
          display: 'flex',
          width: 'calc(100% - 32px)',
        }}
      >
        {upcomingInvoice && (
          <View style={{ flex: 1 }}>
            <Text style={styles.footerText}>
              Total to pay today:{` `}
              <Text style={styles.footerBoldText}>
                {formatCurrency({
                  amount: upcomingInvoice.amount,
                  languageTag: language,
                  currency: (bundlePrice?.currency ||
                    subscriptionPrice?.currency ||
                    dnaUploadPrice?.currency) as string,
                })}
              </Text>
            </Text>
          </View>
        )}

        <View style={{ width: 100 }}>
          <Button
            label="Continue"
            color="primary"
            onPress={goToCheckout}
            disabled={!isPaymentSelectionComplete}
          />
        </View>
      </div>

      <ProductDescriptionSheet
        isOpen={openedModal === 'REVIVE_SUBSCRIPTION'}
        onClose={() => setOpenedModal(null)}
        descriptionList={[
          'Learn your unique genetic code',
          'Tailored nutritional recommendations',
          'Learn your superfoods',
          'Make healthy eating convenient',
          '8 Week Immune reset programme',
          'Daily goals to keep you motivated',
        ]}
        title="Revive Subscription"
      />

      <ProductDescriptionSheet
        isOpen={openedModal === 'GENETIC_ANALYSIS_PACK'}
        onClose={() => setOpenedModal(null)}
        descriptionList={[
          "Optimised for AI - 200 million SNP's",
          'Simple cheek swab collection kit',
          '98% call rate for highest accuracy',
          'Results back in 6-8 weeks',
          'Includes all shipping costs',
        ]}
        title="Genetic Analysis Pack"
      />

      <PromoCodeModal
        setPromotionCode={setPromotionCode}
        isOpen={openedModal === 'PROMO_CODE'}
        onClose={() => setOpenedModal(null)}
        promoCodeError={promoCodeError}
        setPromoCodeError={setPromoCodeError}
      />
    </View>
  );
}
